<template>
  <div
    :style="contactUsStyle"
    class="ContactUs is-flex is-column is-expanded is-cover-image"
  >
    <c-x-navigation>
      <go-back-button slot="left" />
    </c-x-navigation>
    <div class="container container--padded p-t-xl is-width-full p-t-xxxl">
      <div class="is-width-1/2-desktop m-l-a m-r-a has-background-white card m-t-xl has-shadow-intense">
        <div class="card-content p-a-l">
          <heading
            color="black"
            size="1"
            class="has-text-centered m-b-xl"
          >
            {{ $t('general.contact_us') }}
          </heading>
          <form
            v-if="!isSent"
            @submit.prevent="submit"
          >
            <form-wrapper :validator="$v.form">
              <form-field :label="$t('forms.email')" name="email">
                <input
                  v-model="validator.$model"
                  slot-scope="{ validator, classes }"
                  :placeholder="$t('forms.email')"
                  :class="[classes, 'input']"
                  type="email"
                  name="email"
                  disabled
                  @input="validator.$touch()"
                >
              </form-field>
              <form-field :label="$t('forms.subject')" name="subject">
                <basic-select
                  v-model="validator.$model"
                  slot-scope="{ validator }"
                  :placeholder="$t('forms.subject')"
                  :values="subjects"
                  value-prop="value"
                  key-prop="value"
                  label-prop="label"
                  @input="validator.$touch()"
                />
              </form-field>
              <form-field :label="$t('forms.message')" name="message">
                <autosize-textarea
                  v-model="validator.$model"
                  slot-scope="{ validator, classes }"
                  :class="[classes, 'textarea']"
                  @input="validator.$touch()"
                />
              </form-field>
              <div class="has-text-right">
                <v-button
                  :loading="isLoading"
                  type="submit"
                  class="is-primary"
                >
                  {{ $t('forms.send') }}
                </v-button>
              </div>
            </form-wrapper>
          </form>

          <template v-else>
            <div class="notification">
              {{ $t('pages.cx.contact_us.message_sent') }}
            </div>
            <div class="has-text-right">
              <router-link
                :to="{ name: 'cx.features' }"
                class="button is-primary"
              >
                {{ $t('pages.cx.contact_us.go_to_features') }}
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { required, email } from 'vuelidate/lib/validators'

import CXNavigation from '@/components/cx/CXNavigation'
import GoBackButton from '@/components/cx/GoBackButton'
import AutosizeTextarea from '@hypefactors/shared/js/components/core/AutosizeTextarea'

/**
 * @module ContactUs
 */
export default {
  name: 'ContactUs',
  components: { AutosizeTextarea, CXNavigation, GoBackButton },

  data () {
    return {
      form: {
        email: '',
        subject: '',
        message: '',
        language: ''
      },
      isSent: false,
      isLoading: false
    }
  },

  validations: {
    form: {
      email: { required, email },
      subject: { required },
      message: { required }
    }
  },

  computed: {
    ...mapGetters(['currentUser']),

    subjects () {
      const text = 'pages.cx.contact_us.subjects.'
      return [
        { label: this.$t(text + 'sales'), value: 'sales' },
        { label: this.$t(text + 'billing'), value: 'billing' },
        { label: this.$t(text + 'onboarding'), value: 'onboarding' },
        { label: this.$t(text + 'enterprise'), value: 'enterprise' },
        { label: this.$t(text + 'other'), value: 'other' }
      ]
    },
    contactUsStyle () {
      return { backgroundImage: `url(${this.$asset('/images/cx/contact_us/background.jpg')})` }
    }
  },

  mounted () {
    this.form.email = this.currentUser.email
    this.form.language = this.$i18n.locale
  },

  methods: {
    submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      this.isLoading = true
      this.$api.post('/cx/contact', this.form)
        .then(() => {
          this.isSent = true
          this.form.message = '' // reset the message
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
