<template>
  <textarea
    ref="textarea"
    :value="value"
    v-on="listeners"
  />
</template>

<script>
import autosize from 'autosize'

export default {
  name: 'AutosizeTextarea',
  props: {
    value: {
      type: String,
      default: ''
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: this.handleChange
      }
    }
  },

  async mounted () {
    await this.$nextTick()
    autosize(this.$el)
  },

  methods: {
    handleChange (e) {
      this.$emit('input', e.target.value)
    },
    focus () {
      this.$refs.textarea.focus()
    }
  }
}
</script>
