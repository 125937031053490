var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ContactUs is-flex is-column is-expanded is-cover-image",style:(_vm.contactUsStyle)},[_c('c-x-navigation',[_c('go-back-button',{attrs:{"slot":"left"},slot:"left"})],1),_c('div',{staticClass:"container container--padded p-t-xl is-width-full p-t-xxxl"},[_c('div',{staticClass:"is-width-1/2-desktop m-l-a m-r-a has-background-white card m-t-xl has-shadow-intense"},[_c('div',{staticClass:"card-content p-a-l"},[_c('heading',{staticClass:"has-text-centered m-b-xl",attrs:{"color":"black","size":"1"}},[_vm._v(" "+_vm._s(_vm.$t('general.contact_us'))+" ")]),(!_vm.isSent)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('form-wrapper',{attrs:{"validator":_vm.$v.form}},[_c('form-field',{attrs:{"label":_vm.$t('forms.email'),"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
var classes = ref.classes;
return _c('input',{directives:[{name:"model",rawName:"v-model",value:(validator.$model),expression:"validator.$model"}],class:[classes, 'input'],attrs:{"placeholder":_vm.$t('forms.email'),"type":"email","name":"email","disabled":""},domProps:{"value":(validator.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(validator, "$model", $event.target.value)},function($event){return validator.$touch()}]}})}}],null,false,4007053763)}),_c('form-field',{attrs:{"label":_vm.$t('forms.subject'),"name":"subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
return _c('basic-select',{attrs:{"placeholder":_vm.$t('forms.subject'),"values":_vm.subjects,"value-prop":"value","key-prop":"value","label-prop":"label"},on:{"input":function($event){return validator.$touch()}},model:{value:(validator.$model),callback:function ($$v) {_vm.$set(validator, "$model", $$v)},expression:"validator.$model"}})}}],null,false,2447832053)}),_c('form-field',{attrs:{"label":_vm.$t('forms.message'),"name":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
var classes = ref.classes;
return _c('autosize-textarea',{class:[classes, 'textarea'],on:{"input":function($event){return validator.$touch()}},model:{value:(validator.$model),callback:function ($$v) {_vm.$set(validator, "$model", $$v)},expression:"validator.$model"}})}}],null,false,1100502631)}),_c('div',{staticClass:"has-text-right"},[_c('v-button',{staticClass:"is-primary",attrs:{"loading":_vm.isLoading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('forms.send'))+" ")])],1)],1)],1):[_c('div',{staticClass:"notification"},[_vm._v(" "+_vm._s(_vm.$t('pages.cx.contact_us.message_sent'))+" ")]),_c('div',{staticClass:"has-text-right"},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":{ name: 'cx.features' }}},[_vm._v(" "+_vm._s(_vm.$t('pages.cx.contact_us.go_to_features'))+" ")])],1)]],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }